/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import shareImage from '../images/new-share-image.jpg';
import image32 from '../images/favicon-32x32.png';
import image16 from '../images/favicon-16x16.png';
import imageApple from '../images/apple-touch-icon.png';

function Seo({ title, customShareImage = '', customUrl = '' }) {

  const metaImage = customShareImage !== '' ? customShareImage : shareImage;
  const metaUrl = customUrl !== '' ? customUrl : 'https://www.speakforthecity.com/';

  return (
    <Helmet
      htmlAttributes={{
          lang: 'en',
      }}
    >
      <title>{title}</title>
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff"></meta>
      <link rel="apple-touch-icon" sizes="180x180" href={imageApple} />
      <link rel="icon" type="image/png" sizes="32x32" href={image32} />
      <link rel="icon" type="image/png" sizes="16x16" href={image16} />

      <meta property="og:image" content={`https://www.speakforthecity.com${metaImage}`} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:title" content="Your chance to shape the City’s future" />
      <meta property="og:description" content="Find out more about the City of London elections, candidates and how to vote." />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content="@cityoflondon"/>
      <meta name="twitter:title" content="Your chance to shape the City’s future" />
      <meta name="twitter:description" content="Find out more about the City of London elections, candidates and how to vote." />
      <meta name="twitter:image" content={`https://www.speakforthecity.com${metaImage}`} />

      <script>
        {
          `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MXDZFMN');
        `
        }
      </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  customShareImage: PropTypes.string,
}

export default Seo
